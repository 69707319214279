<template>
  <div class="tracking-list">
    <h2 class="tracking-list__title title-page">
      <router-link to="/offers" class="tracking-list__link">
        Offers
      </router-link>
      <icon-arrow color="#1E70BB" />
      Tracking links
    </h2>

    <div class="tracking-list__main">
      <h2 class="tracking-list__main-title mb-2">Table</h2>

      <a href="" id="downloadlink" ref="csvfiledownload" hidden download="mycsv.csv">
        download
      </a>
      <div class="tracking-list__main-option mb-4 d-flex justify-content-between">
        <v-button
          type="small-blue"
          icon="csv"
          class="btn-xs"
          :disabled="_requestStatusPending"
          @click="exportCsv"
        >
          CSV
        </v-button>
        <VSearch
          class="xl"
          placeholder="Search"
          :initialValue="searchFieldValue"
          @input="onSearchByTable($event, _trackingList)"
          @clear="onClearSearch"
        />
      </div>

      <VLoader v-if="_requestStatusPending" />
      <VTable
        v-else-if="_requestStatusSuccess"
        :headCols="_headCols"
        :bodyRows="_formattedTrackingList"
      >
        <template #col-3="{ row }">
          <a v-if="row.previewUrl" :href="row.previewUrl" target="_blank">{{ row.previewUrl }}</a>
          <template v-else>-</template>
        </template>
      </VTable>
    </div>
  </div>
</template>

<script>
import IconArrow from "@/js/components/icons/IconArrow";
import VSearch from "@/js/components/common/VSearch";
import VTable from "@/js/components/common/VTable";
import TableMixin from '@/js/mixins/tableMixin';
import { requestStatus } from '@/js/utils/constants';

export default {
  name: "TrackingList",
  components: {
    VTable,
    VSearch,
    IconArrow
  },
  mixins: [
    TableMixin
  ],
  data() {
    return {
      requestStatus,
      defaultColumns: [
        {
          id: 0,
          slug: 'offerName',
          name: 'Offer Name',
          sort: false,
          orderBy: 'def'
        },
        {
          id: 1,
          slug: 'payout',
          name: 'Payout',
          sort: false,
          orderBy: 'def'
        },
        {
          id: 2,
          slug: 'countries',
          name: 'Countries',
          sort: false,
          orderBy: 'def'
        },
        {
          id: 3,
          slug: 'previewUrl',
          name: 'Preview URL',
          sort: false,
          orderBy: 'def'
        },
        {
          id: 4,
          slug: 'trackingLink',
          name: 'Tracking link',
          sort: false,
          orderBy: 'def'
        },
      ],
    };
  },
  computed: {
    _headCols() {
      return this.defaultColumns;
    },
    _trackingList() {
      return this.$store.state.trackingList.trackingList.map((item, key) => ({
        id: key,
        offerName: item.relationship.offer.name,
        payout: item.relationship.ruleset.platforms[0] && item.relationship.ruleset.platforms[0].label || 'All',
        countries: item.relationship.ruleset.countries[0] && item.relationship.ruleset.countries[0].label || 'All',
        previewUrl: item.preview_url,
        trackingLink: this.generateLink(item.url) || '-',
      }));
    },
    _formattedTrackingList() {
      return this._trackingList.filter((item) => this.foundTableRowsIds.includes(item.id));
    },
    _fetchingTrackingListStatus() {
      return this.$store.state.trackingList.fetchingTrackingListStatus;
    },
    _requestStatusPending() {
      return this._fetchingTrackingListStatus === requestStatus.PENDING || this.foundTableRowsIdsStatus === requestStatus.PENDING;
    },
    _requestStatusSuccess() {
      return this._fetchingTrackingListStatus === requestStatus.SUCCESS || this.foundTableRowsIdsStatus === requestStatus.SUCCESS;
    },
  },
  mounted() {
    this.getTrackingList();
  },
  methods: {
    generateLink(url) {
      const { linkParams } = JSON.parse(localStorage.getItem('formParamsOffer'));
      let link = '';
      for (let key in linkParams) {
        if (linkParams[key] !== '')
          link += `${key}=${linkParams[key]}&`;
      }
      return `${url}?${link}`.slice(0, -1);
    },

    async getTrackingList() {
      const formData = {};

      const {
        selectedOffersIds,
        isGenerateAllOffers
      } = JSON.parse(localStorage.getItem('formParamsOffer'));

      //formData
      formData.include_additional_urls = false;
      formData.is_all_offers = isGenerateAllOffers;
      formData.network_offer_ids = selectedOffersIds;

      try {
        await this.$store.dispatch('trackingList/getTrackingList', formData);
      } catch (err) {
        console.log('=> ERR', err);
      }
    },

    exportCsv() {
      const tableHeaders = this.defaultColumns.map(c => c.name);
      const trackingList = this.$store.state.trackingList.trackingList;
      const csvContent = "data:text/csv;charset=utf-8,"
        + tableHeaders.join(', ') + "\n"
        + this._trackingList.map((link, key) => {
          return [
            link.offerName,
            link.payout,
            this.getCountries(trackingList[key].relationship.ruleset.countries),
            link.previewUrl,
            link.trackingLink,
          ].join(', ');
        }).join("\n");

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "TrackingLinks.csv");
      document.body.appendChild(link); // Required for FF
      link.click();
    },

    getCountries(countries) {
      if (!countries || !countries.length) {
        return 'All';
      }
      return countries.map(c => (c.targeting_type === 'include' ? '+' : '-') + c.label).join('|');
    },
    onClearSearch() {
      if (!this.searchFieldValue.length) return;
      this.onClearSearchByTable();
      this.getTrackingList();
    },
  },
  watch: {
    _fetchingTrackingListStatus(status) {
      if (status === requestStatus.SUCCESS) {
        this.setTableRowsIds(this._trackingList);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tracking-list {
  &__title {
    display: flex;
    align-items: center;
  }

  &__link {
    text-decoration: none;
    color: var(--blue-main);
  }

  &__main {
    width: 100%;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: var(--main-box-shadow);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__main-option {
    width: 100%;
  }
}

</style>